<template>
  <div>
    <v-menu v-model="modelObj.menu" :close-on-content-click="false" min-width="auto">
      <template #activator="{ on, attrs }">
        <v-text-field class= 'xs6 sm4 md3 lg3' :label="label" hide-details outlined dense readonly :append-icon="appendIcon" :prepend-inner-icon="prependInnerIcon"
        v-on="on" v-bind="attrs" v-model="modelObj.dateTime" validate-on-blur :clearable="isClearable" @click:clear="setDateTime(false)"
        :rules="isRequired ? modelObj.dateTime ? [] : [(val) => !!val && !/^\s*$/.test(val) || $t('message.validation.required')] : []"></v-text-field>
      </template>
      <v-card width="auto">
        <v-card-title class="pa-0">
          <v-tabs v-model="modelObj.activeTab" centered grow background-color="primary" color="white">
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="font-weight-bold">{{ $t('message.common.date') }}</v-tab>
            <v-tab class="font-weight-bold" :disabled="!modelObj.date"> {{ $t('message.common.time') }} </v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-tabs-items v-model="modelObj.activeTab">
            <v-tab-item>
              <v-card tile>
                <v-card-text class="pa-0">
                  <v-date-picker v-model="modelObj.date" show-adjacent-months no-title @input="$set(modelObj, 'activeTab', 1)" :weekday-format="getDayFormat" :max="dateMax"></v-date-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card tile>
                <v-card-text class="pa-0">
                  <v-time-picker v-model="modelObj.time" format="24hr" :max="timeMax" @click:minute="updateDateTime()"></v-time-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['label', 'appendIcon', 'value', 'required', 'clearable', 'prependInnerIcon', 'dateMax', 'timeMax'],
  data () {
    return {
      modelObj: {
        menu: false,
        activeTab: 0
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.setDateTime(this.value)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isRequired () {
      return typeof this.required === 'string'
    },
    isClearable () {
      return typeof this.clearable === 'string'
    }
  },
  methods: {
    setDateTime (value) {
      if (value) {
        this.modelObj.date = this.value.split('T')[0]
        this.modelObj.time = this.value.split('T')[1]
        if (this.value.split(' ').length === 2) {
          this.modelObj.date = this.value.split(' ')[0].split('.').reverse().join('-')
          this.modelObj.time = this.value.split(' ')[1]
        }
        this.modelObj.dateTime = this.$formatter.formatDate(this.modelObj.date, 'YYYY-MM-DD', 'DD.MM.YYYY') + ' ' + this.modelObj.time
        this.$forceUpdate()
      } else this.updateDateTime(true)
    },
    updateDateTime (clear) {
      if (clear) {
        this.modelObj.date = null
        this.modelObj.time = null
        this.modelObj.dateTime = null
        this.$emit('input', null)
        this.$forceUpdate()
      } else if ((this.modelObj.dateTime || (this.modelObj.date && this.modelObj.time))) {
        this.modelObj.dateTime = this.$formatter.formatDate(this.modelObj.date, 'YYYY-MM-DD', 'DD.MM.YYYY') + ' ' + this.modelObj.time
        this.$emit('input', this.modelObj.date + 'T' + this.modelObj.time)
      }
      this.modelObj.menu = false
      this.modelObj.activeTab = 0
    },
    getDayFormat (date) {
      const lang = this.$i18n.locale === 'no' ? 'nb' : 'en'
      return moment(date).locale(lang).format('dd')
      // return dayString.charAt(0).toUpperCase() + dayString.slice(1)
    }
  }
}
</script>
